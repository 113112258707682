import React from "react"
import FadeIn from "transitions/fade-in"

const LegalHeader = ({ data }) => {
  const { title, date } = data

  return (
    <header
      className="container mb-22 mt-32 md:mt-40 lg:mt-48 "
      style={{ maxWidth: "1109px" }}
    >
      <div className="flex -ml-16">
        <aside className="md:w-4/12"></aside>
        <FadeIn>
          <div className="pl-16">
            <h1 className="mb-4 font-serif leading-snug text-5xl font-medium">
              {title}
            </h1>
            <h5 style={{ color: "#5A5E62" }}>Updated: {date}</h5>
          </div>
        </FadeIn>
      </div>
    </header>
  )
}

export default LegalHeader
