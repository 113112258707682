import React, { useEffect } from "react"
import slugify from "slugify"
import FadeIn from "transitions/fade-in"

const LegalContent = ({ sections }) => {
  return (
    <section
      className="container mt-10 mb-40 lg:mt-20"
      style={{ maxWidth: "1109px" }}
    >
      <div className="flex-wrap items-start -ml-16 md:flex">
        <aside className="legal__menu">
          <div className="mb-4 font-serif text-midnight c-rich-text has-line-bullets">
            <ul className="mb-0">
              {sections.map(section => (
                <li key={slugify(section.heading)} className="">
                  <a href={`#${anchor(section.heading)}`} className="">
                    {section.heading}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </aside>

        <main className="pl-16 md:w-8/12">
          {sections.map((section, i) => (
            <FadeIn key={`section-${slugify(section.heading)}`}>
              <div
                className={`body-content c-rich-text has-asterisk-bullets ${
                  i > 0 ? "mt-18" : ""
                }`}
                id={section.heading && anchor(section.heading)}
              >
                {section.heading && (
                  <h2 className="mb-4 font-serif text-xl leading-snug sm:text-4xl">
                    {section.heading}
                  </h2>
                )}
                {section.content && (
                  <div
                    className="mb-6"
                    dangerouslySetInnerHTML={{ __html: section.content }}
                  ></div>
                )}
              </div>
            </FadeIn>
          ))}
        </main>
      </div>
    </section>
  )
}

export default LegalContent

const anchor = text => {
  return `${slugify(text, { lower: true })}`
}
