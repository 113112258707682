import React, { useEffect } from "react"
import { graphql } from "gatsby"
import SEO from "components/seo"
import Transition from "components/transition"
import LegalHeader from "components/legal/legal-header"
import LegalContent from "components/legal/legal-content"
import handleExternalUrls from "utils/handleExternalUrls"

const LegalPage = ({ data: { wpPage }, pageContext }) => {
  if (!wpPage) return null

  useEffect(() => {
    handleExternalUrls()
  }, [wpPage]) // Only re-run if wpPage changes

  const { seo, title } = wpPage
  const content = wpPage.content.legalPage

  return (
    <Transition>
      <SEO
        seo={seo}
        bodyAttributes={{
          class: "page--legal",
        }}
      />
      <LegalHeader data={{ title, date: content.lastUpdated }} />
      <LegalContent sections={content.sections} />
    </Transition>
  )
}

export const query = graphql`
  query Legal($id: Int!) {
    wpPage(databaseId: { eq: $id }) {
      ...SEO
      title
      content: legalPage {
        legalPage {
          lastUpdated
          sections: section {
            heading
            content
          }
        }
      }
    }
  }
`

export default LegalPage
